* {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-weight: 800;
}

.calculator-wrapper {
    background-color: #dac6f8;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

}

.calculator {
    background-color: #faa5c3;
    width: 350px;
    height: 550px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px #af3b71;
}

.display-container {
    width: 100%;
    height: 120px;
    background-color: #ffffff;
    font-size: 2rem;
    text-align: right;
    padding: 0.2em;
    color: #4c4d4e;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#expression {
    font-size: 1.3rem;
}

#display {
    margin-bottom: 0;
}

.buttons {
    font-size: 2rem;
    height: 4rem;
    width: 4rem;
    color: #ffffff;
    margin: 0.1em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
}

.nums-container {
    width: 75%;
    display: flex;
    flex-wrap: wrap;
}

.nums-container .buttons {
    background-color: #e74e95;
}

.nums-container .buttons:hover {
    background-color: #d13980;
}

.ops-container {
    width: 25%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 0.5em;
}

.ops-container .buttons {
    background-color: #974db9;
}

.ops-container .buttons:hover {
    background-color: #8431aa;
}

#zero {
    width: 8rem;
    border-radius: 50px;
}

#clear {
    margin-right: 20%;
    margin-top: 0.5em;
    margin-bottom: 0.3em;
    width: 8rem;
    border-radius: 50px;
    background-color: #fdd520;
}

#clear:hover {
    background-color: #ffc400;

}

